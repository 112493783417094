import { registerApplication, start } from 'single-spa'

registerApplication({
  name: '@cx/platform-console',
  app: PlatformConsole,
  activeWhen: ['/'],
})

async function PlatformConsole() {
  placeLoader()
  const app = await System.import('@cx/platform-console')
  removeLoader()
  return app
}

function placeLoader() {
  document.body.appendChild(
    Object.assign(document.createElement('div'), {
      role: 'progressbar',
      tabindex: '0',
      class: 'eds-spinner eds-spinner--large',
      id: 'single-spa-loader',
    })
  )
}

function removeLoader() {
  document.getElementById('single-spa-loader').remove()
}

start({
  urlRerouteOnly: true,
})
